

.test-container {
	margin-bottom: vr(-1);
	background-color: rgba($primary-color, .9);
	padding: vr(1) 0;
	@media #{$medium-up} {
		margin-bottom: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	&--open {
		background-color: $primary-color;
		position: static;
		padding-bottom: 0;
	}
    &--ajax {
        position: absolute;
        background-color: rgba($primary-color, .95);
        bottom:0;
        max-height: 0;
        @include single-transition(.9s);
        overflow: hidden;
        z-index: -1;
        width:100%;
        padding:0;
        #tancar-joc{
            position:absolute;
            top:2rem;
            right: 2rem;
            background-color:transparent;
            z-index: 4;
            img{
                width: 30px;
            }

        }
    }
    &--move {
        max-height: 1200px;
        height: 90%;
        z-index: 1;
        @include single-transition(.9s);
    }
	.pas{
		margin:1.5rem 0 0;
        display: none;
        @media #{$medium-up} {
            display: block;
        }
        img{
            max-width: 75%;
            @include single-transition(.5s);
            &.completat{
                opacity: 0;
                 @include single-transition(.5s);
                &.carregat{
                    opacity: 1;
                }
            }
        }
	}
    .test-content{
       position: absolute;
        margin: 0 auto;
        width: 100%;
        padding-top: 50px;
        height: 600px;
        @media #{$medium-up} {
            position: absolute;
            margin: 0 auto;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            padding:0;
        }

    }
	.message-test{
		background-color:#fff;
		max-width: 700px;
		margin:4.5rem auto;
		padding:3.5rem 2rem 2rem;
		position: relative;
		img{
			position:absolute;
			top:0;
			left:50%;
			top:-52px;
			transform: translateX(-50%);
		}
		p{
			color:#000;
			@include typi(t3);
            line-height: 1.5;
			&:after{
				content: none;
			}
            a{
                color: $primary-color;
                &.button:hover{
                    color:#fff;
                }
            }
		}
	}
}
#page{
    min-height: 350px;
    overflow: hidden;

}
/*****************************************/
/* Archive me https://tympanus.net/Development/ItemTransitions/index2.html*/
/*****************************************/
/* 4 classes: navInLeft, navInRight, navOutLeft, navOutRight */

@-webkit-keyframes slideInFromBottom {
    from {
        -webkit-transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(0);
    }
}

@keyframes slideInFromBottom {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.fxArchiveMe .navOutNext {
    -webkit-animation: scaleHalfDown 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
    animation: scaleHalfDown 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
}

.fxArchiveMe .navInNext {
    z-index: 1001;
    opacity: 1;
    -webkit-animation: slideInFromBottom 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
    animation: slideInFromBottom 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
}

.fxArchiveMe .navOutPrev {
    -webkit-animation: slideOutToBottom 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
    animation: slideOutToBottom 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
}

.fxArchiveMe .navInPrev {
    -webkit-animation: scaleHalfUp 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
    animation: scaleHalfUp 0.7s forwards cubic-bezier(0.7, 0, 0.3, 1);
}


.fxArchiveMe li::after,
.fxArchiveMe .navOutNext::before {
    opacity: 1;
}

.fxArchiveMe li::before,
.fxArchiveMe li.current::after,
.fxArchiveMe .navInNext::after,
.fxArchiveMe .navInPrev::after {
    opacity: 0;
}

.fxArchiveMe .navInNext::after {
    transition: none;
}

@-webkit-keyframes scaleHalfDown {
    from {} /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
    to {
        -webkit-transform: scale(0.6);
        opacity: 0;
    }
}

@keyframes scaleHalfDown {
    from {} /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
    to {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0;
    }
}

@-webkit-keyframes slideOutToBottom {
    from {} /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
    to {
        -webkit-transform: translateY(100%);
    }
}

@keyframes slideOutToBottom {
    from {} /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}

@-webkit-keyframes scaleHalfUp {
    from {
        opacity: 0;
        -webkit-transform: scale(0.6);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@keyframes scaleHalfUp {
    from {
        opacity: 0;
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}