.main-header {
	position: relative;
	background-color: $primary-color;
	text-align: center;
	@media #{$large-up} {
		text-align: left;
	}
	&__tools {
		position: absolute;
		text-transform: uppercase;	
		bottom: -30px;
		right: 25px;
		@media #{$medium-up} {
			position: absolute;
			right: 25px;
			top: 10px;
		}
	}
	&__item {
		//display: none;
		@media #{$medium-up} {
			@include typi(s1);
			// @include semibold;
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			color: $body-font-color;
			&:after {
				content: '';
				display: inline-block;
				width: 1px;
				height: 27px;
				background-color: $body-font-color;
				vertical-align: middle;
				margin-left: 10px;
				margin-right: 5px;
				.js-menu-open &,
				.has-bg & {
					background-color: #fff;
				}
			}
			&:hover {
				text-decoration: underline;
			}
			.js-menu-open &,
			.has-bg & {
				color: #fff;
			}
		}
	}
	
	.menu{
		display: inline-block;
		vertical-align: bottom;
		margin-bottom: .5em;
		ul{
			display: flex; /* set the display property to flex */
			list-style: none; /* remove the default bullet points */
			margin: 0; /* remove default margins */
			padding: 0; /* remove default padding */		
			li{
				list-style: none;
				margin:1em .5em;
				@media #{$medium-up} {
					margin:1em;
				}
				a{
					color:#fff;
					display: block;					
					padding: .4em 1em;
					border-radius: 25px;
					border: solid 1px #fff;
					text-decoration: none;
					span{
						display: none;
					}
					@media #{$medium-up} {
						border-radius: 9999px;
						span{
							display: inline;
						}
					}
					&.active{
						background-color: #fff;
						color:#000;
					}
				}
			}
		}
	}
}

.site-title {
	font-size: 1em;
	margin: 1em 0;
	/*margin: 0;
	padding: 0;
	border: 0;
	position: absolute;
	top: vr(.75);
	z-index: 4	;*/
	
	display: inline-block;
	
	&:after {
		display: none;
	}	
	img {
		width: 280px;
		@media #{$medium-up} {
			width: 394px;
			height: 100px;
		}
	}
	@media #{$medium-up} {
		margin: 1.5em 0;
	}
}

/*.lupa-s {
	display: none;
	@media #{$medium-up} {
		display: inline-block;
		border-right: 0;
		background-color: transparent;
		padding-left: 0;
		transition: opacity .1s;
		&:after {
			display: none;
		}
		&:hover {
		  opacity: .8;
		}
		img {
			position: relative;
			top: -2px;
		}
	}
}
.cercador-form{
	position: absolute;
	top:0;
	z-index: 10;
	background-color: #fff;
	background: rgba(#414141,.95);
	opacity: 0;
	visibility: hidden;
	width: 100%;

	&.open{
		visibility: visible;
	 	-webkit-animation: searchform-open 0.3s forwards cubic-bezier(0.4,0,0.2,1);
    	animation: searchform-open 0.3s forwards cubic-bezier(0.4,0,0.2,1);
    }
    &.close{
		-webkit-animation: searchform-hide 0.2s forwards cubic-bezier(0.4,0,1,1);
  		animation: searchform-hide 0.2s forwards cubic-bezier(0.4,0,1,1);
    }

	width: 100%;
	padding: 25px 0;
	form{
		width: 450px;
		max-width: 100%;
		margin:0 auto;
		background-color: #fafafa;
		padding:6px 25px 6px 45px;
		position: relative;
		fieldset{
			padding: 0;
			border:none;
			input{
				width: 100%;
			}
			button{
				position: absolute;
				top:5px;
				left:15px;
				height: 20px;
				width: 20px;
				padding: 0;
				margin: 0;
				&.tancar-cerca{
					left: auto;
					right:15px;
				}
			}
			input,button{
				background-color: #fafafa;
				border-color: transparent;
			    border-style: solid;
			    border-width: 0 1px;
			    outline: none;
			}
		}
	}
}
@-webkit-keyframes searchform-open {
    0% {
        opacity: 0
    }
    40% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
@keyframes searchform-open {
    0% {
        opacity: 0
    }
    40% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
@keyframes searchform-hide {
	0%{
		opacity:1;
		visibility:visible
	}
	100%{
		opacity:0;
		visibility:hidden
	}
}
*/