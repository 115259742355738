.more {
  @include bold;
  @include typi(s2);
  border-radius: 6px;
  margin: 0 0 vr(1);
  display: inline-block;
  background-color: #ececec;
  border: 0;
  transition: background-color .2s, color .2s;
  padding: .25em .75em;
  &:hover {
    background-color: lighten(#ececec,5%);
  }
  &--2 {
    color: #fff;
    background-color: #000;
    background-color: rgba(0,0,0,.7);
    &:hover {
      color: $body-font-color;
    }
  }
  &--2 {
    color: #fff;
    background-color: #000;
    background-color: rgba(0,0,0,.7);
    &:hover {
      color: $body-font-color;
    }
  }
  &--sang {
    color: $primary-color;
    background-color: #fff;
    border:solid 1px $primary-color;
    &:hover {
      color: $body-font-color;
    }
  }
}