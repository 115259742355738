.share-list,
.share-list-mult {
    margin-left: 0;
    .collapsible & {
        text-align: center;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0;
        margin-left: 0;
        &.only-desktop {
            display: none;
        }
        @media #{$medium-up} {
            &.only-mobile {
                display: none;
            }
            &.only-desktop {
                display: inline-block;
            }
        }
        &:before {
            display: none;
        }
        ul {
            display: none;
            position: absolute;
            top: 20px;
            left: 0;
        }
        &.share-button {
            cursor: pointer;
            padding: .5em;
            z-index: 3;
            @media #{$medium-up} {
                position: absolute;
                bottom: 1.15rem;
                left: 90px;
                &--cal {
                    bottom: .9rem;
                }
            }
            em {
                display: block;
                @extend .ico-compartir;
                border-bottom: none;
            }
            &:hover {
                background-color: #ececec;
                box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
                ul {
                    display: block;
                    background-color: #ececec;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
                    width: 200px;
                    padding: .5em;
                    z-index: 2;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -4px;
                        height: 4px;
                        width: 35px;
                        left: 0;
                        background-color: #ececec;
                    }
                }
            }
        }
    }
    a {
        display: inline-block;
        margin: 0 vr(.4);
        transition: opacity .1s;
        vertical-align: middle;
        border: 0;
        &:hover {
            opacity: .8;
            border: 0;
        }
    }
    li:first-child {
        a {
            margin-left: 0;
        }
    }
    li:last-child {
        a {
            margin-right: 0;
        }
    }
    .tool {
        li {
            a {
                margin: 0 vr(.3);
            }
        }
    }
    &--link {
        @extend .ico-link-compartir;
    }
    &--email {
        @extend .ico-email-compartir;
    }
    &--tw {
        @extend .ico-twitter-compartir;
    }
    &--fb {
        @extend .ico-facebook-compartir;
    }
    &--g {
        @extend .ico-googleplus-compartir;
    }
    &--wt {
        @extend .ico-whatsapp-compartir;
    }
    &--tl {
        @extend .ico-telegram-compartir;
    }
    &--small {
        /*.locations & {
      margin-top: -1em;
      @media #{$medium-up} {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }*/
        a {
            margin: 0 vr(.5) 0 0;
            @media #{$medium-up} {
                margin: 0 0 0 vr(.5);
            }
        }
        .share-list--link {
            @extend .ico-link-compartir-small;
        }
        .share-list--email {
            @extend .ico-email-compartir-small;
        }
        .share-list--tw {
            @extend .ico-twitter-compartir-small;
        }
        .share-list--fb {
            @extend .ico-facebook-compartir-small;
        }
        .share-list--g {
            @extend .ico-googleplus-compartir-small;
        }
        .share-list--wt {
            @extend .ico-whatsapp-compartir-small;
        }
        .share-list--tl {
            @extend .ico-telegram-compartir-small;
        }
    }
}

.share-list-mult {
    @media #{$medium-up} {
        .share-list--email {
            //background-image: url('img/ico-email-compartir-mult.png');
            background-image: url('img/ico-email-compartir_2021.png');
            background-repeat: no-repeat;
            width: 69px;
            height: 69px;
            background-position: 0;
        }
        .share-list--tw {
            //background-image: url('img/ico-twitter-compartir-mult.png');
            background-image: url('img/ico-twitter-compartir_2021.png');
            background-repeat: no-repeat;
            width: 69px;
            height: 69px;
            background-position: 0;
        }
        .share-list--fb {
            //background-image: url('img/ico-facebook-compartir-mult.png');
            background-image: url('img/ico-facebook-compartir_2021.png');
            background-repeat: no-repeat;
            width: 69px;
            height: 69px;
            background-position: 0;
        }
    }
}