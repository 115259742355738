/*.main-content {
  padding-top: vr(3);
  .has-bg & {
  	padding-top: 0;
  }
  @media #{$medium-up} {
  	padding-top: vr(1.5);
  }
  .home & {
  	background: #333;
  	.section{
  		background-color: #fff;
  	}
  }
}*/

.main-content {
    h1{
      margin: vr(2) 0;
     font-size: 2em;
      @media #{$medium-up} {
        font-size: 2.7em;
      }
      &.st{
        margin-bottom: .45em;
      }
      span{
        color:$primary-color;
      }
    }
    p.subtitle{
      margin-bottom:2em;   
      color: #747474;
      font-size: 1.35em;
    }

}
.zones {
  margin: 2em 1em;

  li {
    margin-bottom: 2em;
    padding: 1em;
    border: 1px solid $primary-color;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    padding: 0;
    &:hover {
      border-color: $primary-color;
      box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
    }

    img {
      display: block;
      margin: 0 auto .5em;
    }

    a {
      display: block;
      border: none;
      text-align: center;
      font-size: 1.35em;
      position: relative;
      height: 100%;
      padding: 1em;
      @media only screen and (min-width: 1023px) {
        font-size: 2em;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    li {
      flex-basis: calc(33.33% - 2em);
      border-radius: 5px;
      transition: all .2s ease-in-out;
      align-items: center;
      text-align: center;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;
        justify-content: center;
       span{
          display: block;
          max-width: 90%;
        }
      }
    }
  }
}


