.main-footer {
  //margin-top: vr(2);
  @media #{$medium-up} {
    margin-top: vr(2);
  }
  border-top: 1px solid #ccd1d6;
  &__top {
    text-align: center;
   
    padding: vr(2) 0 0;    
  }
  p {
    margin-bottom: vr(.25);
  }
  p.big {
    @include bold;
    @include typi(t3);
    margin-top: vr(1);
  }
  a {
    color: $primary-color;
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  &__bottom {
    text-align: center;
  }
  .copy-right-text{
    margin-top: vr(1);
    font-size: .85em;
    a{
      border-bottom:$body-font-color 1px solid;
      color: $body-font-color;
      &:hover{
        border-bottom:$primary-color 1px solid;
      }
    }
  }
  &__legal {
    @include typi(s1);
    margin-top: vr(.5);
    span {
      margin-right: vr(.5);
    }
    ul {
      display: inline-block;
      li {
        display: inline-block;
        &:after {
          content: '- ';
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
      a {
        // display: inline-block;
        margin: .5em 0;
        border-bottom:$body-font-color 1px solid;
        color: $body-font-color;
        &:hover{
          border-bottom:$primary-color 1px solid;
        }
      }
    }
  }
}
