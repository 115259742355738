.menu-lang {
  margin-bottom: 0;  
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
  li {
    display: inline-block;
    margin-right: .65em;
    &:last-child{
      margin-right: 0;
    }
  }
  a {
    display: inline-block;
    border:solid 1px #fff;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    padding: 0 vr(.25);
    background-color: $primary-color;
    @include single-transition();
    @media #{$medium-up} {
      padding: vr(.25) vr(.5);
      .js-menu-open &,
      .has-bg & {
       color: #fff;
       background-color: transparent; 
      }
      &:hover {
        text-decoration: underline;        
      }
    }
  }
  .current {
    a {
      background-color: #fff;

      color: $body-font-color;
      @media #{$medium-up} {
        .js-menu-open &,
        .has-bg & {
         color: $body-font-color;
         background-color: #fff; 
        }
      }
    }
  }
  @media #{$medium-up} {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  } 
}