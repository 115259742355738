.cookies {
    @include typi(s1);
    background: #000;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: vr(1);
    padding-bottom: vr(1);
    p {
        color: #fff;
        margin-bottom: 0;
        &:before {
            content: '';
            display: block;
            margin: 0 auto vr(1);
            @media #{$medium-up} {
                display: inline-block;
                margin: 0 vr(.5) 0 0;
                vertical-align: middle;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 1px solid;
        &:hover {
            border-bottom: 0;
        }
    }

    .button {
        display: block;
        width: 10rem;
        margin: vr(1) auto 0;
        border: 0;
        color: $primary-color;
        &:hover {
            color: #fff;
        }
        @media #{$medium-up} {
            margin-top: vr(.5);
        }
    }
}
