.steps {
	list-style: none;
	color: #fff;
	@media #{$medium-up} {
	   @include typi(s1);
	}

	@include block-grid(1);
	margin-bottom: vr(.5);
	@media #{$medium-up} {
		@include block-grid(3);
		margin-bottom: vr(.5);
	}

	span {
		@include typi(t2);
		@include bold;
		display: block;
		margin: 0 auto;
		margin-bottom: vr(.5);
		background-color: $primary-color;
		border-radius: 100px;
		height: vr(2);
		width: vr(2);
		line-height: vr(2);
	}

}