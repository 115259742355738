.button {
  @include typi(btn);
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(0.75);
  padding: 0.6rem vr(1);
  text-transform: uppercase;
  text-align: center;
  background-color: #fff;
  color: $primary-color;
  border-radius: 6px;
  border: solid 3px $primary-color;
  // line-height: 45px;
  width: 100%;
  transition-property: background-color, color, border-color;
  transition-duration: 0.1s;
  @media #{$medium-up} {
    margin-bottom: 0;
  }
  .home & {
    width: auto;
    font-size: 0.6em;
  }
  .collab-box & {
    width: 100%;
    @include bold;
    &:hover {
      background-color: #fff;
    }
  }
  .collab-box--lt &,
  .collab-box--rb & {
    &:hover {
      color: $primary-color;
    }
  }
  .collab-box--rt &,
  .collab-box--lb & {
    &:hover {
      color: #242424;
    }
  }
  .testimoni & {
    width: 100%;
  }
  .apd & {
    border-width: 2px;
  }
  @media #{$medium-up} {
    width: auto;
  }
  &:hover,
  &:focus {
    background-color: $primary-color;
    color: #fff;
    border-color: $primary-color;
    text-decoration: none !important;
  }

  &--secondary {
    border: 3px solid #fff !important;
    background-color: transparent;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #fff;
      color: $primary-color;
      border: solid 3px #fff;
      text-decoration: none !important;
    }
  }

  &--tertiary {
    @include semibold;
    // line-height: 38px;
    border: 0;
    color: $body-font-color;
    background-color: #eee;
    padding-left: vr(1.5);
    padding-right: vr(1.5);
    &:hover,
    &:focus {
      border: 0;
    }
  }

  &--neg {
    border: 0;
    @include semibold;
    @include typi(t4);
    // line-height: 45px;
    padding-left: vr(1.5);
    padding-right: vr(1.5);
    width: auto;
    &:hover,
    &:focus {
      border: 0;
    }
    @media #{$medium-up} {
      // line-height: 64px;
    }
  }

  &--pos {
    // border: 0;
    color: #fff;
    background-color: $primary-color;
    @include semibold;
    @include typi(t4);
    // line-height: 45px;
    padding-left: vr(1.5);
    padding-right: vr(1.5);
    width: auto;
    &:hover,
    &:focus,
    &[aria-expanded="true"] {
      background-color: #fff;
      color: $primary-color;
      // border: 0;
    }
    // @media #{$medium-up} {
    //   line-height: 64px;
    // }
  }

  &--test {
    background-color: #fff;
    color: $body-font-color;
    border: 1px solid #fff;
    @include bold;
    &:hover,
    &:focus {
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
    }
  }

  &--small {
    @include typi(s1);
    padding: 0.5rem vr(0.75);
  }

  &--smaller {
    @include typi(s2);
    padding: 0.5rem vr(0.75);
  }
  &--smallest {
    @include typi(s3);
    padding: 0.125rem vr(0.5);
    border-width: 2px;
  }

  &--login {
    @include typi(s0);
    background-color: #9eba27;
    border-color: #9eba27;
    color: #fff;
    @include semibold;
    padding: vr(0.5) vr(2);
    &:hover,
    &:focus {
      background-color: transparent;
      color: #9eba27;
      border-color: #9eba27;
    }
  }

  &--reg {
    @include typi(s0);
    background-color: #FFF;
    border-color: #000;
    color: #000;
    @include semibold;
    padding: vr(0.5) vr(1.05);
    &:hover,
    &:focus {
      background-color: #f2f2f2;
      color: #000;
      border-color: #000;
    }
  }

  &--back {
    @include typi("s2");
    @include bold;
    width: auto;
    border-width: 1px !important;
    padding: rem-calc(5) vr(0.5);
    svg {
      vertical-align: sub;
      margin-right: vr(0.25);
    }
    &:hover,
    &:focus {
      svg {
        filter: invert(100%);
      }
    }
  }

  &--cancel {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: #333;
    &:hover,
    &:focus {
      color: #f2f2f2;
      border-color: #8c8c8c;
      background-color: #8c8c8c;
    }
  }

  &--save {
    svg {
      margin-right: vr(0.25);
      vertical-align: bottom;
    }
    &:hover,
    &:focus {
      svg {
        filter: invert(100%);
      }
    }
  }

  &--btplasma {
    //border: 0;
    //background-color: #ececec;
    transition: background-color .2s,color .2s;
    border-color: #bc6b2b;
    color: #bc6b2b;
    &:hover,
    &:focus {
      background-color:  #bc6b2b;
      border-color: #bc6b2b;
      color: white;
    }
  }
}
