.heading-wrapper {
  background-color: gray;
  background-size: cover;
  background-position: 50% 0;
  margin-bottom: vr(2);
  @media #{$medium-up} {
  	padding: vr(1) 0;
  }
  &--donate {
    background-color: #d3dd24;
    background-image: linear-gradient(to bottom, #a7b710, #d3dd24);
  }
  &--about-us {
  	background-image: url('http://placehold.it/1500x200');	
  }
  h1 {
    color: #fff;
  }
}