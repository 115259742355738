@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}
h1,
.t1 {
  @include typi("t1");
  @include extrabold;
  //text-transform: uppercase;
  color: #4D4D4D;
  text-align: center;
  margin-bottom: vr(1);

}
h2,
.t2 {
  @include typi("t2");
  @include regular;
  margin-bottom: vr(0.5);
  .section & {
    margin-bottom: vr(1.5);
  }
  .bg--1 & {
    color: #fff;
  }
  .bg--2 & {
    color: #fff;
  }
  .reserves & {
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .subportada-intro & {
    @include bold;
    margin-bottom: vr(0.75);
  }
  b {
    @include extrabold;
  }
  &.calendari {
    @include typi("t4");
    @include regular;
    @media #{$medium-up} {
      @include typi("t3");
      @include regular;
    }
  }
}
h3,
.t3 {
  @include typi("t3");
  margin-bottom: vr(0.5);
  @include italic;
}
h4,
.t4 {
  @include typi("t4");
}
.s0 {
  @include typi("s0");
}

// títols específics
.simple-title {
  text-transform: none;
  margin-bottom: 0;
  @include light;
  @include typi(t0);
  color: $body-font-color;
  &:after {
    display: none;
  }
}
.simple-title-2 {
  @include bold;
  margin: vr(1) auto vr(2);
}
.titol-alt {
  @include typi(titol-alt);
  @include light;
  margin-bottom: vr(1);
}
.titol-bold {
  @include typi(titol-bold);
  @include bold;
  margin-bottom: vr(0.75);
  &:not(:first-child) {
    margin-top: vr(1.5);
  }
}

p,
ol,
ul,
dl,
figure {
  margin: 0 0 vr(0.75);
}
.simple-dl {
  width: 100%;
  overflow: hidden;
  border: 1px solid #979797;
  div {
    @include cf;
    // border-bottom: 1px solid #d9d9d9;
    padding: vr(0.5);
    &:nth-child(even) {
      background-color: #f4f4f4;
    }
  }
  dt {
    @include bold;
    @media #{$medium-up} {
      float: left;
      width: 40%;
    }
  }
  dd {
    margin: 0;
    @media #{$medium-up} {
      float: left;
      width: 60%;
    }
    * {
      margin-bottom: 0;
    }
  }
}

ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
  // @media #{$medium-up} {
  //   margin-left: vr(1.5);
  //   list-style-position: outside;
  //   padding-left: 0;
  // }
}
main ul {
  list-style: none;
  @media #{$medium-up} {
    // margin-left: vr(1.5);
  }
  li {
    padding-left: vr(0.75);
    position: relative;
    margin-bottom: vr(0.5);
    &:before {
      content: "";
      width: 7px;
      height: 7px;
      display: block;
      left: 0;
      top: 0.5em;
      position: absolute;
      background-color: $primary-color;
      border-radius: 100px;
    }
    ul {
      @include typi(s1);
      margin-top: vr(0.5);
      li {
        margin-bottom: vr(0.25);
        padding-left: 0;
        list-style: circle;
        margin-left: vr(1.5);
        &:before {
          display: none;
        }
      }
    }
  }
}
::selection {
  color: #fff;
  background-color: lighten($primary-color, 30);
  // background-color: $secondary-color;
}
strong,
b {
  @include bold;
}
em,
i {
  @include italic;
}
hr {
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  margin: vr(1) 0;
  height: 0;
}
main {
  a {
    color: $primary-color;
    text-decoration: none;
    border-bottom: rem-calc(1) solid $primary-color;
    transition: border-width 0.1s;
    word-break: break-word;
    &:hover {
      border-color: transparent;
    }
  }
}
.mostrant {
  @include typi(s1);
  border: solid #dcdcdc;
  border-width: 2px 0;
  padding: vr(0.5) 0;
  margin-bottom: vr(2);
}
.dest-text {
  @include typi(dest-text);
  @include light;
}
.dl-link {
  &:before {
    content: "";
    // @extend .publicacions-icon-baixar-negre;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 8px;
  }
  &:hover {
    &:before {
      // @extend .publicacions-icon-baixar-taronja;
    }
  }
}
.lead {
  @include bold;
  text-align: center;
  margin-bottom: vr(2);
  @media #{$medium-up} {
    padding: 0 vr(2);
  }
}

// llistat simple, sense klander
.simple-list {
  margin-left: 0;
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

// llistat destacat
.dest-list {
  @include bold;
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// llistat amb filets
.borders-list {
  margin-bottom: vr(2);
  li {
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: vr(0.5);
    padding-bottom: vr(0.5);
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

.inline-list {
  text-align: center;
  font-size: 0;
  li {
    display: inline-block;
    margin: 0 vr(0.25) vr(0.5);
    vertical-align: middle;
  }
}

// block-grid
[class*="block-grid"] > li {
  &:before {
    display: none;
  }
}

// intro text
.intro-text {
  @include bold;
  margin-bottom: vr(1.5);
}

// smaller text
@media #{$medium-up} {
  .smaller-text {
    p,
    ul,
    ol {
      @include typi(s1);
    }
  }
  .smaller {
    @include typi(s1);
  }
}

.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}