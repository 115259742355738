.alert {
    background-color: #fff;
    padding: vr(.75);
    position: absolute;
    top: 5rem;
    left: $column-gutter/2;
    right: $column-gutter/2;
    text-align: center;
    z-index: 4;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    -webkit-animation:movingboxMobile 3s 1;
    -moz-animation:movingboxMobile 3s 1;
    animation:movingboxMobile 3s 1;
    min-height: 100px;
    @media #{$medium-up} {
        position: fixed;
        top: vr(4);
        right: 0;
        left: auto;
        text-align: left;
        padding-right: rem-calc(50);
        width: 25rem;
        -webkit-animation:movingbox 4s 1;
        -moz-animation:movingbox 4s 1;
        animation:movingbox 4s 1;
    }
    &:before {
        content: '';
        display: block;
        @extend .ico-alerta-reserves;
        margin: 0 auto vr(.5);
        @media #{$medium-up} {
            position: absolute;
            letter-spacing: vr(1);
            top: vr(1);
        }
    }
    p {
        @media #{$medium-up} {
            padding-left: rem-calc(50);
            //white-space: nowrap;
            margin-bottom: 0;
        }
    }
    &__txt-1 {
        @include typi(t3);
        margin-bottom: vr(.5);
    }
    &__txt-2 {
        @include typi(s1);
        @include bold;
        text-transform: uppercase;
        color: $primary-color;
        margin-bottom: vr(.5);
    }
    .button {
        margin-bottom: 0;
        @media #{$medium-up} {
            //width: 100%;
            //white-space: nowrap;
            //padding-left: 0;
            //padding-right: 0;
            margin-left: rem-calc(50);
            margin-top:vr(.5);
        }
    }

    &.js-closed {
        display: none;
    }
    button {
        position: absolute;
        top: vr(.25);
        right: $column-gutter/2;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }
}

@-webkit-keyframes movingbox{
    from{right:-60%;}
    to{right:0;}
}
 @keyframes movingbox{
    from{right:-60%;}
    to{right:0;}
}

@-webkit-keyframes movingboxMobile{
    from{top:-60%;}
    to{top:5rem;}
}
 @keyframes movingboxMobile{
    from{top:-60%;}
    to{top:5rem;}
}