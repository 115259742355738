.skip-link {
    position: absolute;
    top: vr(1);
    right: 100%; /* moves off screen */
    background-color: $white;
    padding: vr(.5);
    font-size: rem-calc(14);
    text-decoration: none;
    color: $primary-color;

    &:focus {
        right: auto;
        left: vr(1);
        z-index: 10;
    }
}
