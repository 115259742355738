.info-block {
  h3,h4,h5 {
    @include typi(titol-bold);
    @include bold;
    margin-bottom: vr(.5);
  }
  p,ul {
  	//@include typi(s3);
  	@media #{$medium-up} {
    	@include typi(s1);
    }
  }
}