.bg {
  // padding: vr(1.5) 0;
  &--1 {
    background-color: $primary-color;
    color: #fff;
  }
  &--2 {
    background-color: #242424;
    p {
    	color: #fff;
    }
    a {
      color: #fff;
      border-bottom-color: inherit;
    }
  }
  &--3 {
    background-color: #f5eed6;
  }
  &--4 {
    background-color: #f4f4f4;   
    background: linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 26%);
  }
  &--5 {
    background-color: #f4f4e3;
  }
  &--6 {
    background-color: #EDEDED;
  }
  &--7     {
    background-color: #439898;
  }
}
