.error404 {
  h1{
    
    color:$primary-color;
    text-transform: none;
    
    &:after{
      content:none;
    }
  }
  .main-content{
    
    padding:3em 0 5em;
    img{
      max-width: 450px;
      height: auto;
    }
    p{
       
        margin:2rem 0;
    }
  }
}