.down {
  display: none;
  @include typi(s1);
  @include bold;
  text-transform: uppercase;
  color: #fff;
  text-decoration: underline;
  position: absolute;
  left: 50%;
  bottom: vr(1);
  text-align: center;
  text-decoration: none;
  position: absolute;
  margin-left: -38px;
  border: 0;
  transition: opacity .1s;
  z-index: 2;
  .home & {
    bottom: vr(2);
  }
  .splash & {
    bottom: vr(1);
  }
  @media #{$medium-up} {
    display: block;
    bottom: vr(.5);
    .splash & {
      bottom: vr(.75);
    }
    &:hover {
      opacity: .8;
    }
  }
  @media #{$xlarge-up} {
    .splash & {
      bottom: vr(1);
    }
  }
}
