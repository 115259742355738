
.box-message{
  border:solid #000 6px;
  border-radius:16px;
  padding: 2em;
  margin: 2em 0;
  h2{
    margin: 1em 0 .5em;
  }
  svg{
    color:#000;
    width: 64px;
    height: 64px;
    }
  &.box-error{
    border-color:#d4351c;
    svg{
      color:#d4351c;
      width: 64px;
      height: 64px;
      }
    .button{
      margin-top: 2em;
    }
    .resend-form{
      margin-top: 2em;
      fieldset{
        padding: 0;
      }
      .button{
        margin-top: 0;
      }
    }
  }
}


.inscdonacionshospitals, .inscdonacions {
  .button--tertiary {
    @include semibold;
    // line-height: 38px;
    border: 0;
    color: $body-font-color;
    background-color: #eee;
    padding: vr(.6) vr(1.5);
    &:hover,
    &:focus {
      border: 0;
    }
  }

  form.reserva, form.hospital {
    position: relative;
  }

  form.reserva .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0.5;
    background-color: #f4f4e3;
    top: 0;
    right: 0;
    img {
        position: absolute;
        //top: 50%;
    }
  }

    form.reserva {
        select {
            width: 100%!important;
            max-width: initial;
        }
        #hospital_day {
            margin-top: 1.3125rem;
            margin-bottom: 0;
        }
    }


  .button:disabled {
    opacity: 0.5;
  }

  .calendarRow {
    margin-top: 1.5rem;
  }

  #hospital_hour {
    font-size: 1em;
  }

  .calendari {
    margin-bottom: 1em;
    background-image: linear-gradient(to bottom, #ffffff, #eaeaea);
    width: 100%;
    font-size: rem-calc(16);
    text-align: center;
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px;
    th {
      text-align: center;
      &.dies{
        border-bottom: 1px #ccc solid;
      }
      abbr[title] {
         border:none;
        text-decoration: none;
        font-size: rem-calc(10);
        text-transform: capitalize;
      }
      span {
        font-size: rem-calc(16);
      }
      a {
        background-color: $white;
        border: none;
      }
    }
    td {
      padding: rem-calc(8);
      color: #ccc;
      a {
        border: none;
        width:27px;
        margin:0 auto;
        display: block!important;

      }
      span.selected{
        color: $white!important;
        background-color: $primary-color!important;
        margin:0 auto;
        display: block;
        border-radius: 50%!important;
        width:27px;
      }
    }
    .cal--selected {
      color: $white!important;
      border-radius: 50%!important;
      display: block!important;
      background-color: $primary-color!important;
      width:27px;
      margin:0 auto;
      &:selection{
        background-color: $primary-color!important;
      }
    }
    .cal--obert {
      color: #000;
      background-color: transparent;

    }
    .arrow--next {
      float: right;
      margin-right: rem-calc(10);
    }
    .arrow--prev {
      float: left;
      margin-left: rem-calc(10);
    }
  }

  .wrapper-calendar.poblacio{
    .calendari--plasma{
      td{
        a.cal--obert{
           border-radius: 50%!important;
          display: block!important;
          border:solid 1px $primary-color!important;
          color:$primary-color;
          width:27px;
          margin:0 auto;
        }
      }
    }
  }


/*    input[type="search"],
    select,
    textarea {
      padding: vr(.25) vr(.5);
      height: rem-calc(50);
    }*/
    select#hospital, .select2, {
        font-size: .83333rem;
        line-height: 1.6;
        border-radius: 4px;
        border: solid 1px #d6d6d6;
        width: 100%;
        color: #4c4c4c;
        padding: .375rem .75rem;
        height: 3.125rem;
        background: white;
    }

    .select2-container--default .select2-selection--single {
        border: none;
        margin-top: .4em;
    }

    .select2-container--default .select2-results>.select2-results__options {
        color: #4c4c4c;
        font-size: .83333rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 15px;
        right: 15px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none;
        background: url(/media/img/lupa-cercador.svg);
        width: 25px;
        height: 25px;
        top: 0;
        left: 0;
    }

}
.top-header.top-header--simple{
  background-color: $primary-color;
  h1{
    color: #fff;
    text-transform: none;
    font-size: rem-calc(30);
    margin: 0;
    &.b{
      margin-bottom: 2.5rem;
    }
  }
  @media #{$medium} {
    padding: 1rem 0;
    margin-bottom: 0;
  }

}
.inscdonacionshospitals{
  .grups{
    margin-bottom: rem-calc(35);

  }
  &.grups .fi{
      margin-top: 2em;
      h1{
        margin-bottom: .5em;
      }
    }
  h2{
    margin: 0;
  }


  .houdiniForm ul.tria-dia{
      text-align: left;
      margin:1em 0 2em;
  }
  .houdiniForm ul.tria-dia li{
      margin:1em 1em 0 0;
      max-width: 43%;
      display: inline-block;
      vertical-align: middle;
      @media #{$medium} {
        max-width: 200px;
      }
  }

  .tria-dia a {
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      padding: 1rem;
      display: block;
      color: #000;
      opacity: .8;
      font-size:.9em;

  }
  .tria-dia  a.actiu {
        border-color: #1c863a;
      color: #1c863a;
      font-family: open_sansbold,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
      font-weight: 700;
      font-style: normal;
      position: relative;
      opacity: 1;
  }
  .tria-dia  a:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      top: -12px;
      position: absolute;
      right: -12px;
  }
  .tria-dia  a.actiu:after {
      background: url(/media/css/img/white-check.svg) no-repeat center center #1c863a;
      background-size: 50% auto;
  }

  #dades-reserva{
    background: #fff;
    padding: 1em .5em;
   /* -webkit-box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
    box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);*/
    /*border: 1px solid #f4f4f4;    
    box-shadow: 0 16px 32px -20px #e0e0e0;    */
    @media #{$medium} {
       padding: 2em 1em;
    }
    @media #{$large} {
      padding: 3em 1.5em;

    }
    form{
      margin: 0;
    }
     p.type-label{
        text-align: left;
        margin:0;
        font-size:1em;          
        font-weight: 600;
      } 
    #hora .opcions{

        label {
            float: left;
          &.selected{
            position: relative;
            &:after {
                  content: "";
                  display: block;
                  width: 24px;
                  height: 24px;
                  border-radius: 100px;
                  top: 0;
                  position: absolute;
                  left: 50%;
                  margin-left: -12px;
                  background: url(/media/css/img/white-check.svg) no-repeat center center transparent;
                  background-size: 50% auto;
              }
          }
        }
        legend {
            padding: 0;
            border: none;
            font-family: open_sansregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            font-weight: 600;
            font-size: .875rem;
            letter-spacing: initial;
            &.title{
              @include light;
                  font-size: 1.2rem;
                  letter-spacing: .8px;
                   padding: 1.5rem 0 0.75rem;   
            }
        } 
             
    }



    .dades-hospital{
      margin-bottom:1.5rem;
      .more{
          margin-bottom:2.5rem;
          @media #{$medium} {
            margin-bottom:1.5rem;
          }
      }
      .avis{
        background: #FFF7D0;
        font-size: rem-calc(20);
        margin-bottom:.5em;
        line-height: 1.2;
        margin-top: 1em;
        padding: 1em 1em 1em 2.6em;
        position: relative;
        svg{
          position: absolute;
          left:.5em;
          top:.72em;
          
        }
       
      }
      .descripcio{
        margin-top: .5em;
        margin-bottom: 1.5em;
      }
      p{
        margin: 0;
        @include light;
        &.small{
          font-size: rem-calc(12);
        }
        &.seleccio{
          @include bold;
          font-size: rem-calc(25);
          margin-bottom:.5em;
          line-height: 1.2;
          &.b {
           font-size:1.4em;
          }
        }
      
        &.lloc,&.horari  {
          margin: 0 0 1em;
          font-family: open_sans_lightregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
          font-weight: 300;
          font-style: normal;
        }
        &.horari{
          margin-bottom: 0.5em;
        }
      }
      a.button{
        font-size: rem-calc(12);
        text-transform: none;
        margin-top: 1rem;
        display: inline-block;
        max-width: 130px;
        padding:.3rem;
        border:solid 1px $primary-color;
        @media #{$medium} {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 0;
        }
      }
      /*border-bottom:solid 1px #f7f7f7;
      padding-bottom: 1rem;
      margin-bottom:1rem;*/
      border: 1px solid #f4f4f4;
      box-shadow: 0 16px 32px -20px #e0e0e0;     
      padding: 1em;
      background: #f8f8f8;
      @media #{$medium} {                    
           padding: 2em;                   
        }
      @media #{$large} {
        padding: 3em;          
          margin-right: 2rem; 
                             
        }

    }
    [aria-hidden] {
      display: none;
    }
    fieldset{
      position: relative;
    }
    .pas{
      span{
        display: block;
        width: 30px;
        height: 30px;
        border:solid 1px $primary-color;
        border-radius: 50%;
        line-height: 30px;
        background-color: #fff;
        color: $primary-color;
        font-size: rem-calc(16);
        text-align: center;
        @include single-transition(.45s);

      }
      &.completed{
          span{
            background-color: $primary-color;
            color: #fff;
          }
      }
      position: absolute;
      top: -43px;
      @media #{$medium} {
        position: absolute;
        left: -50px;
        top: -45px;
        min-height: 100%;
        &:after{
          content: '';
          height: 100%;
          width: 1px;
          background-color: #efefef;
          display: block;
          position: absolute;
          left: 15px;
        }
        &#pas-dades:after{
          content: none;
        }
      }

    }
    legend{
       @include light;
       text-transform: none;
       text-align: left;
       color:$body-font-color;
       padding-top: 0;
       position: relative;
       padding-left: 1.82em;
       position: relative;
       svg{
        position: absolute;
        left: 0;
        top: -2px;
        width: 32px;
        height: 32px;
        color: $primary-color;
       }
      /*@media #{$medium} {
        padding-left: 0;
      }*/
    }
    input,select{
      margin-top: .2rem!important;
    }
    #hospital_day{
      height: auto;

      border:none;
      outline: 0;
    }
    .info-dia{
        position: relative;
        padding:1em 2.6em 0;
        font-size: rem-calc(11);
        margin-top: -6px;
        margin-bottom: 8px;
        svg{
          position: absolute;
          left: 0;
          top:9px;
        }
      }
    .wrapper-calendar{
     // background-image: linear-gradient(to bottom,#fff,#eaeaea);
      margin:-1.8em 0 2em;
      padding: 1em .5em;
      border: 1px solid #ccc;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      position: relative;
      background: #fff;
      @media #{$large} {
        padding: 1em 1.5em;
      }

    }
    table.calendari{
      background-image:none;
      @media #{$medium} {
        width: 47%;
        margin-right:3%;
        float:left;

        &.last{
           margin-right:0;
            margin-left:3%;
        }
      }
      td{
        a{
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: rem-calc(14);
          @media #{$large} {
            width: 27px;
            height: 27px;
            line-height: 27px;
            font-size: rem-calc(16);
          }
        }
      }
    }
    #hora-select{
       margin-bottom: 1.5rem;
    }
    #hora{
      position: relative;
      .info{
        position: relative;
        padding:1em 2.6em 0;
        svg{
          position: absolute;
          left: 0;
          top:9px;
        }
      }
      .loading{
        left: 146px;
        top: -25px;
        height: auto;
      }
      .opcions{

        min-height: 65px;

        label{
          margin: 1em 4% 0 0;
          width: 46%;
          border: 1px solid #FFCDD2;
         
          border-radius: 25px;
          padding: 1rem;
          float:left;
          min-width: 125px;
          min-height: 59px;
          cursor: pointer;
          text-align: center;
          font-size: 1.25em;
          transition: all 140ms cubic-bezier(.25, .46, .45, .94);
          &:focus-within,&:focus-visible{
            outline: 2px solid #000;
          }
          &.selected{
            border-color:#388E3C;
            color:#296c2d;
            box-shadow: 3px 3px 0 0 #388E3C;
            transform: translate(-0.15rem, -0.15rem);
            background-color: #C8E6C9;
            &:focus-within,&:focus-visible{
              outline: none;
            }
            
          }
          &.complet{
            opacity: .5;
          }
          @media #{$medium} {
            margin: 1em 1.5em 0 0;
            width: auto;
          }
          input{
            margin: 0;
            margin-right:  .5em;
          }
          &:hover{
            /*background-color: #FFCDD2;*/
            box-shadow: 3px 3px 0 0 #FFCDD2;
                      
            transform: translate(-0.15rem, -0.15rem);
          }
        }
        .complet{
          legend{
            padding-bottom: 0.75rem;
          }
          label{
            float:none;
            border:none;
            padding:0;
            min-height: auto;
            min-width: auto;
            margin: 0;
            &.selected{              
              color:#000;
              &:after{
                content: none;
              }
            }
          }
          .button{
                margin-top: 1.5rem!important;
          }
        }
      }
    }
    .alert-form {
      margin-top: 0;
      p{
        padding: .2rem 0 .2rem .75rem;
        @media #{$medium} {
          padding-left: .25rem;
        }
      }
    }
    #dadespersonals {
        .loading{
            left: 50%;
            top: 50%;
            height: auto;
        }
        .info{
            position: relative;
            //padding:1em 2.6em 0;
            svg{
              position: absolute;
              left: 0;
              top:9px;
            }
        }
    }


    .detall{
      //#FFF6FA
      background-color: lighten($primary-color,54%);
      padding: 1em;
      margin: 1em 0;
      border-radius:4px;
      display: flex;
      flex-wrap: wrap;
      #detall-hospital{
        display: block;
      }
      h2{
        font-size: rem-calc(12);
        margin: 0;
        flex-grow: 1;
         width: 30%;
         padding-right: 1em;
         border-right: solid 1px $primary-color;
         @media #{$medium} {
          font-size: rem-calc(16);
          width: 20%;
         }

      }
      div{
        //font-size: rem-calc(14);
        margin: 0;
        flex-grow: 1;
        width: 70%;
        padding-left:1em;
        @media #{$medium} {
          padding-left:2em;
          width: 80%;
         }
        p{
          margin: 0;
        }
        a{
          font-size: rem-calc(10);
          color:#be2b76;
        }
      }

    }

  }
  .nota{
      margin-top: 2rem;
    }
  .menu-fixed,.top{
    display: none!important;
  }
  form.reserva .loading{
    background: #fff;
  }

}
.calendar-component__triangle{
    position: absolute;
    left:0;
    top: -17px;
    width: 35px;
    height: 18px;
    transition: left linear 0.3s;
    -o-transition: left linear 0.3s;
    -moz-transition: left linear 0.3s;
    -webkit-transition: left linear 0.3s;
    &:before {
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      content: " ";
      height: 0;
      position: absolute;
      left: calc(50% - 12px);
      right: auto;
      width: 0;
      top: 3px;
      border-bottom: 13px solid #ccc;
    }
    &:after {
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      content: " ";
      height: 0;
      position: absolute;
      left: calc(50% - 12px);
      right: auto;
      width: 0;
      top: 4px;
      border-bottom: 13px solid #fff;
    }
}

.diaDona{
  background-color: #783fa0;
  color: #FFF !important;
}

.diaDona a{
  color: #FFF !important;
}

.ocupacio{
    font-size:.45em;
    display: block;
}
.es .ocupacio{
    font-size:.415em;    
}
.ocupacio span{
    display:inline-block;
    border-radius:50%;
    background-color:green;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.ocupacio span.mitja{
    background-color:orange;
}
.ocupacio span.complet{
    background-color:red;
}
#compliment_lopd{
    margin-right:.3em;
}

.inscdonacionshospitals #dades-reserva #hora .info.avis,
.inscdonacionshospitals #dadespersonals .info.avis{
    font-size: 1.4em;
    color: #bc2b30;
    padding-top: 0;
    padding-left:0;
    font-weight: bold;
}
.inscdonacionshospitals #dades-reserva .complet{
  fieldset{
    legend{
      font-family: open_sansbold,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
      font-weight: 700;
      padding-bottom: 0.75rem;
      font-style: normal;
      font-size: .85rem;
      letter-spacing: normal;
      padding-left:0;
      padding-top:2em;
      @media #{$medium} {
        padding-top:0;          
      }
    }
    #compliment_lopd{
      margin-bottom: 0;
    }
     #legalhover{
      margin: 1rem 0 0;
     }
    #legalhover p{
      font-size: .7rem;
      font-weight: 400;
      padding-left: 0;
      
    }
    button#rebre-avis{
      margin-top: 1.5rem;
    }
    #compliment_lopd-error{
      margin-top: 1em;
    }
  }
}
.inscdonacionshospitals #dades-reserva  fieldset.options legend{
  font-size: .875rem;
  font-weight: 600;
  margin: 0;
  padding-left: 0;
  font-family: open_sansregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;  
}
.inscdonacionshospitals .grups{
    margin-bottom: 0;
}
fieldset.options label{
    display:inline;font-weight:initial
}
.pas svg{
    fill:#bc2b30;
}
.pas.completed svg{
    fill:#fff;
}
.grups .buscar-form p.seleccio{
    max-width: 700px;
}
.a-progress-bar {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: .125rem;
    background-color: #E8E8E8;
    color: #296c2d;
    display: block;
    height: .25rem;
    width: 100%;
}
.progress-bar {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: .125rem;
   background-color:#E8E8E8;
   color:#296c2d;
    display:block;
    height:.25rem;
    width: 100%;

}
.inscdonacionshospitals .progress-meter-heading{
    font-size: 1.3em;
    margin-bottom: .5em;
    line-height: 1;
        font-family: open_sans_lightregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: 300;
}
.inscdonacionshospitals .progress-meter-heading b{
    //color: #be2b76;
    color: $primary-color;
}
.inscdonacionshospitals .progress-meter-heading span{
    font-size: .675em;
    }
.inscdonacionshospitals .progress-meter-heading span.box-franges{
    font-size: 1em;
    box-shadow: 6px 6px 0px #f4f4e3;
    transform:translate(-3px, -3px);
    border:solid 1px #f4f4e3;
    padding:2em;
    display: block;
}    
.inscdonacionshospitals #dades-reserva p.progress-meter-text{
    font-size: .8em;
    text-align: right;
    margin-top: .5em;
}
.progress-bar::-webkit-progress-bar {
    border-radius: .125rem
}

.progress-bar::-webkit-progress-value {
    border-radius: .125rem;
    -webkit-transition: width 5s ease;
    transition: width width 5s ease;

}

.progress-bar::-moz-progress-bar {
    border-radius: .125rem
}

.progress-bar::-ms-fill {
    border-radius: .125rem
}

.progress-bar--red::-webkit-progress-bar {
    background-color: #E8E8E8
}

.progress-bar--red::-webkit-progress-value {
    background-color: #296c2d
}

.progress-bar--red::-moz-progress-bar {
    background-color: #296c2d
}

.progress-bar--red::-ms-fill {
    background-color: #296c2d
}

#hospital_day-error{
    position: absolute;
    left: 53px;
    top: 8px;
    line-height: 1;
}
#legal-error{
    margin-top: 5px;
}
#legalhover{
  padding-left: 1em;
  
  margin-top: .5em;
  p{
    padding: 0;
    margin-bottom: 0;
    font-size: 1em;
  }
}
#dades-reserva #hora .opcions label#hour-error,#dades-reserva #hora .opcions label#franja-error{
    padding: .30em 1em;
    width: 96%;
    display: block;
    margin: 0;
    min-height: auto;
    font-size: 1em;
    float: none;
    border-radius:0;
    border: solid 1px $primary-color;
    background-color: $primary-color;
    color: #fff;
    font-weight: 400;
    @media #{$medium} {
      width: auto;
      position: absolute;
      top: -.3em;
      left: 80px;
      &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          margin-top: -7px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent $primary-color transparent transparent;
      }
    }
    
}
@media #{$medium} {
  #dades-reserva #hora .opcions label#franja-error{
    left: 140px;
  }
}
#compliment_lopd.error{
      outline: 1px solid red;
}
.fitxa  .mfp-bg {
    opacity: .1;
}

.white-popup {
  border: 1px solid #bc2b30;
  border-radius: 8px;
  position: relative;
  background: #fff;
  padding: 4.5em 2em 2em;
  width: auto;
  max-width: 250px;
  font-size: .9em;
  margin: 20px auto;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(60,64,67,.05), 0 4px 8px 3px rgba(60,64,67,.15);
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 1em;
    left: 50%;
    margin-left:-17px;
    @extend .ico-alerta-reserves;
  }
}
select#acompanyants{
  background: #fff;
}
.inscdonacionshospitals .main-content p.link.b{
    margin-top: 2em;
}
.inscdonacionshospitals .main-content  button,.inscdonacionshospitals .main-content  .button {
    width: auto;
}
@media only screen and (min-width: 46.3125em){

    #hospital_day-error{
           top: -13px;
    left: 250px;
    }
    .white-popup {
      max-width: 400px;
    }
    .inscdonacionshospitals .main-content p.link.b{
        margin-top: 0;
    }
}
.share-list a.bt-wt{
      border: 1px solid #bc2b30;
      margin: 0 0 1.5em 0;
      width: 100%;

}
.share-list a.bt-tg{
    border: 1px solid #bc2b30;
    margin-left:0;
    width: 100%;
  img{
    padding: 4px;
  }
}

.recordatori{
    background: #fff;
    padding: 2em 2em .5em;
    max-width: 80%;
    margin: 2em auto;
    border: solid 3px $primary-color;
    border-radius: 6px;
    div.big{
      font-size: 1.3em;
      margin-bottom: 1.5em;
    }
    /*box-shadow: 0 2px 10px rgba(0,0,0,.10);*/
    svg{
      color: $primary-color;
      margin-bottom: 1em;
    }
    @media #{$medium} {
      max-width: 550px;
    }
    li{
      text-align: left;

      margin-bottom: .2em;
      span{
        @include bold;
        font-size: .9em;
      }
      @media #{$medium} {
          font-size: .9em;
          border-left: solid 1px rgba(0,0,0,.10);
          &:first-child{
            border: none;
          }
          span{
            display: block;
            font-size: .8em;
          }

      }
    }
    @media #{$medium} {
      &.b{
        li{
          padding-bottom: .5em;
          border-left: none!important;
          span{
            display: inline-block;
            font-size: 1em;
          }
        }
      }
    }
}
hr.nt{
  margin-top: 0;
}
.privacitat-checkbox{
  text-align: left;
  margin:1em 0;
  #compliment_lopd{
        margin-top: 0.2rem!important;
  }
}
.inscdonacionsplasma{
  .inscdonacionshospitals #dades-reserva #hora .opcions label.complet.selected {
      border-color: #909090;
      
  }
  .inscdonacionshospitals #dades-reserva #hora .opcions label.complet.selected:after {    
      background: url(img/playlist_add_white.svg) center center no-repeat #909090;    
  }
  #dades-reserva #hora .opcions label.complet{
  opacity: 1;color: #909090;
  }
  .nota p{
      font-size: .7em;
      line-height: 1.25;
      padding-bottom:2em;
  }
  .inscdonacionshospitals .houdiniForm .wrapper-list{
    margin: -2.8em 0 2em;
    .tria-dia{
      margin-top: 0;
      li{
        background:#fff;
      }
    }
  }
  @media only screen and (min-width: 46.3125em){
     #dades-reserva #hora .opcions label{
          width: 152px;
          min-width: 152px;
          max-width: 152px;
      }
  }

}