#recollides {
  margin:0;
  padding:0;
  .section{
    padding-top: 1em;
  }
  .no-results{
    font-size: 2em;
    text-align: center;
    padding: 1em;
  }
  .llista {
    li {
      padding-left: 0;
      
      &:before {
        content: none;
      }
    }
    
    p {
      font-size: .85rem;
    }
    
    h2 {
      margin-bottom: 0.5em;
    }
    
    div.box {
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      padding: 1em;
      transition: all .2s ease-in-out;
      
      &:hover {
        border-color: $primary-color;
        box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
      }
      &.punt-fix {
        background: #FFF7D0;
        border-color: #C6BF9A;
        
        .horari {
          font-size: 1.1em;
        }
      }
      .dies {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0 0 1em;
        gap: .75em;
        
        li {
          flex: 0 0 calc(25% - .75em); /* Columns: 4, multiline, with margin gap */
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0.75em 1.2em !important;
          border: 1px solid $primary-color;;
          font-size: .85em;
          margin: .2em 0 .7em;
          line-height: 1.3;
          text-align: center;
          text-transform: uppercase;
          position: relative;
          
          &.anulada {
            border-color: #EA004B;
            background-color: #EA004B;
            color: #FFF;
            font-size: .65em;
          }
          
          a {
            border: none;
          }
          
          /* Same height */
          &::before {
            content: '';
            display: table;
            padding-top: 100%;
            background: none;
          }
          
          span {
            display: block;
            font-size: 1.75em;
            font-weight: bold;
          }
        }
      }
    }
    
    .more {
      font-size: .85em;
      left: auto;
      right: 1em;
    }
  }
}
.submenu {
  
  justify-content: center;
  align-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  display: none;
  @media #{$medium-up} {
    display: flex;
    margin-bottom: 56px;
  }
  li:before {
    content: none;
  }
  
  a {
    padding: 14px 24px;
    border: 1px solid #ccd1d6;
    color: #000;
    box-shadow: none;
    border-radius: 25px;
    background-color: transparent;
    font-size: .75rem;
    line-height: .75rem;
    letter-spacing: -.02em;
    @include single-transition();
    &:hover{
      border-color: #000;
    }
    &.active {
      border-color: $primary-color;
      background-color: $primary-color;
      color: #fff;
    }
  }
}
.search-wrapper {
  position: relative;
  max-width: 450px;
  margin: 0 auto 2em;
  
  .cancel-icon {
    position: absolute;
    right: 18px;
    top: 50%;
    margin: -20px 0 0 0 !important;
  }
  
  .search-icon {
    position: absolute;
    left: 18px;
    top: 50%;
    margin: -20px 0 0 0 !important;
  }
  
  #nav-search-input {
    display: flex;
    width: 100%;
    height: 60px;
    margin-bottom: 0;
    padding: 6px 62px;
    align-items: center;
    border: 1px solid #ccd1d6;
    border-radius: 30px;
  }
}

@media only screen and (min-width: 46.3125em){

#recollides a.button{
        font-size:.7em;
    }
}

@media #{$medium-up} {
  #recollides{
    .medium-block-grid-2 {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 0.5em;
      justify-content: center;
      
      li {
        flex: 0 0 calc(50% - 0.5em); /* Columns: 2, multiline, with margin gap */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1em !important;
        
        .box {
          height: 100%;
          width: 100%;
        }
      }
    }
    
    #recollides {
      .llista {
        div.box {
          padding: 1em 1em 4em;
          
          .button {
            position: absolute;
            bottom: 3em;
          }
        }
      }
    }
  }
}
@media #{$large-up} {
  #recollides{
    .large-block-grid-3 {      
        
        li {
          flex: 0 0 calc(33% - 0.5em); /* Columns: 3, multiline, with margin gap */
          
        }
    }
  }

}