.top {
  background-color: $primary-color;
  padding: vr(.5);
  border-radius: 6px;
  transition: opacity .1s;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  display: block;
  @media #{$medium-up} {
    display: none;
    position: fixed;
    right: 0;
    bottom: vr(1);

    z-index: 2;
    padding: vr(1);
    transform: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media #{$small-only} {
    display: none!important;
  //   padding: vr(.5);
  //   width: 54px;
  //   height: 31px;
  }
  &:hover,
  &:focus {
    opacity: .8;
  }
}
