.basic {
  margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  legend {
    @include typi(t3);
    color: #6c1d41;
    text-align: center;
    padding-bottom: vr(1);
    width: 100%;
  }
  label {
    display: block;
    margin-top: vr(1);
    margin-bottom: vr(0.25);
    a {
      color: $primary-color;
      &:hover {
        text-decoration: none;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="password"],
  select,
  textarea {
    @include typi(s1);
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    width: 100%;
    padding: vr(0.25) vr(0.5);
    color: #4c4c4c;
    // &:focus {
    //   box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $primary-color;
    // }
    &.error {
      border: 1px solid #d93025;
    }
  }
  input[type="search"] {
    box-sizing: border-box;
  }
  button {
    margin-top: vr(1);
    .bg & {
      margin-bottom: 0;
    }
  }
  .help-block{
    font-size:em-calc(11);
    margin-top:9px;
      color: #626a6e;
  }
  .group-form{
    div.columns{
      padding-left: vr(.15);
      padding-right:vr(.15);
      &:first-child{
        padding-left: 0;
        padding-right:vr(.3);
      }
      &:last-child{
        padding-left: vr(.3);
        padding-right: 0;
      }
    }
  }
}
.houdiniForm {
  @include typi(s1);
  fieldset {
    border: 0;
    padding: 0 0 vr(1);
  }
  legend {
    text-transform: uppercase;
    letter-spacing: 0.8px;
    padding: vr(1) 0 vr(0.5);
    width: 100%;
    @include typi(t4);
    @include bold;
    + p {
      text-align: center;
      color: #4c4c4c;
    }
  }
  button {
    margin-top: vr(0.5);
  }
  label {
    padding-right: vr(1);
  }
  input[type="text"],
  input[type="url"],
  input[type="tel"],
  input[type="email"],
  input[type="file"] {
    @include typi(s1);
    border: 0;
    background-color: #ffffff;
    border: solid 1px #d6d6d6;
    width: 100%;
    padding: vr(0.25) vr(0.5);
    color: #4c4c4c;
    margin: vr(0.25) 0 vr(1);
    &.error {
      border: 1px solid red;
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    margin: vr(0.25) 0 vr(1) 0;
  }
  textarea {
    width: 100%;
    border: 0;
    border: solid 1px #d6d6d6;
    margin: vr(0.25) 0 vr(0.75);
    max-height: 200px;
    padding: vr(0.25) vr(0.5);
  }
  select {
    padding: vr(0.25) vr(0.5);
    width: 100%;
    margin: 0.375rem 0 1.125rem;
  }
  .dninifnie {
    select {
      height: 38.5px;
      margin: 0.375rem 0 1.5rem;
    }
  }
  input[type="checkbox"] {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: 0;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
  }
  input[type="radio"] {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: 0;
    -webkit-appearance: radio;
    -moz-appearance: radio;
  }
  label.error {
    margin-top: -20px;
    display: block;
    margin-bottom: vr(1);
  }
  .select2 {
    margin: 0.375rem 0 1.125rem;
  }
  ul {
    li {
      font-size: rem-calc(14);
      margin-bottom: 0;
      padding-left: 0;
      &:before {
        content: none;
      }
    }
  }
}
// validació
label.error {
  @include typi(s1);
  margin-top: vr(0.25);
  color: red;
  &:before {
    content: "";
    // @extend .correu-error;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    margin-top: 1px;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='red' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E");

  }
}

// form vull donar
.loc-form {
  margin: vr(1) 0 vr(2);
  fieldset {
    position: relative;
  }
  legend {
    color: #fff;
    @include bold;
    padding-bottom: vr(0.5);
  }
  label {
    color: #fff;
    text-align: center;
    @include typi(s1);
    margin-bottom: vr(0.5);
    margin-top: 0;
  }
  input {
    height: vr(2);
    border-radius: 6px;
    margin-bottom: 0;
    padding-right: 45px !important;
    -webkit-appearance: none;
  }
  button {
    background-color: transparent;
    position: absolute;
    right: 2px;
    bottom: 7px;
    transition: opacity 0.1s;
    @media #{$medium-up} {
      right: 5px;
      bottom: 10px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
//cercador on puc donar subportada
.cercador-subportada {
  label {
    @include typi(t3);
  }
  @media #{$medium-up} {
    #cercador {
      position: absolute;
      top: 53%;
      transform: translate(-50%, -50%);
      left: 50%;
      min-width: 500px;
      input[type="search"] {
        font-size: rem-calc(16);
      }
    }
    label {
      @include typi(t2);
      @include bold;
      padding-bottom: vr(0.5);
    }
  }
  @media #{$xlarge-up} {
    #cercador {
      top: 47.5%;
    }
  }
}
// form rebre avís
.alert-form {
  margin-bottom: 0;
  legend {
    color: #000;
    @include bold;
    @include typi(s0);
    padding-bottom: vr(0.5);
  }
  /*label {
    @include visually-hidden;
  }*/
  input {
    height: vr(2);
    border-radius: 6px;
    margin-bottom: 0;
    padding-right: 45px !important;
    -webkit-appearance: none;
  }
  input[type="checkbox"] {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: 0;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
  }

  p {
    @include typi(s2);
    line-height: 1.1;
    max-width: 90%;
    padding-left: 0.4em;
    text-align: left;
  }
}

// form test
.pregunta {
  margin: vr(1) 0;
  legend {
    @include visually-hidden;
  }
  fieldset {
    border: 0;
  }
  p {
    @include typi(t3);
    @media #{$medium-up} {
      @include typi(t2);
    }
    max-width: 450px;
    margin: 0 auto vr(0.5);
    &:after {
      display: none;
    }
  }
  figure {
    margin: vr(1.5) 0 vr(2);
  }
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    left: -9999px;
  }
  .opcional {
    display: none;
  }
  .btns {
    li {
      padding-left: 0;
      display: inline-block;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
    label {
      cursor: pointer;
      margin: 0 vr(0.25);
      width: 5rem;
      @media #{$medium-up} {
        width: 8rem;
      }
    }
    .button {
      margin-bottom: 0;
    }
  }
}
//formularis bàsics
.form-basic {
  form {
    fieldset {
      border: none;
      padding: 0;
    }
    legend {
      margin-bottom: vr(1);
      text-align: center;
      @include typi(t2);
    }
    label {
      display: block;
    }
    input,
    textarea,
    select {
      width: 100%;
      max-width: 100%;
      margin-top: vr(0.2);
      margin-bottom: vr(0.6);
    }
    input[type="submit"] {
      width: auto;
      min-width: 75px;
      @include typi(t4);
    }
    input[type="checkbox"] {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-top: 0;
      -webkit-appearance: checkbox;
      -moz-appearance: checkbox;
    }
    .small {
      p {
        @include typi(s2);
        line-height: 1.2;
        max-width: 90%;
        padding-left: 0.4em;
      }
      a {
        text-decoration: underline;
        border: 0;
      }
    }
  }
}
.grups {
  .basic {
    label {
      font-size: rem-calc(14);
      font-weight: 600;
      margin: 0;
    }
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="tel"],
    select,
    textarea {
      padding: vr(0.25) vr(0.5);
      height: rem-calc(50);
    }
    select {
      margin-top: rem-calc(21);
      height: 46px;
      background-image: linear-gradient(to bottom, #ffffff, #eaeaea);
      max-width: rem-calc(158);
    }

    .alert-form {
      margin-top: rem-calc(25);
      p {
        @include light;
        padding-left: rem-calc(25);
        max-width: 94%;
      }
    }
  }

  .form {
    padding-bottom: 0;
  }
  label.error {
    margin-top: -20px;
    display: block;
    margin-bottom: vr(1);
    color: $primary-color;
    font-size: rem-calc(10);
    &:before {
      content: none;
    }
  }
  #quantitat-error {
    margin-top: 0px;
  }
}
