// body {
//   overflow-x: hidden; // per evitar scroll horitzonal en alguns navegadors quan s'utlitza la classe .u-wider en algun element.
// }
.js-menu-open {
  @media #{$medium-up} {
    overflow: hidden;
  }
}

.section {
  padding: vr(1.5) 0;
  &.bg--4{
    padding: 0;
  }

}

// text intro
.intro {
  @include typi(intro);
  @include light;
  color: #fff;
  // margin-top: vr(1);
  @media #{$medium-up} {
    margin-top: vr(2);
  }
  @media #{$large-up} {
    margin-top: vr(3);
  }
}

// home intro
.home-intro {
  @include light;
  @include typi('home-intro');
  color: #fff;
  margin-top: vr(1.5);
  h2{
    @include light;
  }
  p {
    margin-bottom: vr(.75);
  }
}

// text informatiu
.info {
  @include typi(s2);
  color: #4c4c4c;
}

// mapa
.map {
  margin-bottom: vr(2);
  img {
    width: 100%;
  }
}

// test ràpid
.test {
  h2 {
    text-transform: uppercase;
  }
  p {
    color: #fff;
    &:after {
      content: '';
      display: block;
      margin: vr(.5) auto 0;
      @extend .ico-mostra-joc;
    }
  }
}

// títol pàgina
// .page-title {
//   display: block;
//   text-align: center;
//   text-transform: uppercase;
//   @include typi(t3);
//   @include light;
//   text-align: center;
//   margin: vr(2) 0 vr(1);
//   color: #6c1d41;
// }

// figure
figure {
  text-align: center;
}
figcaption {
  @include typi(s2);
  text-align: left;
  padding: vr(.5) 0 0;
  @media #{$medium-up} {
    width: 50%;
  }
  &.full {
    width: 100%;
  }
}

// paràgraf destacat
.dest {
  @include typi(t3);
  @include light;
  text-align: center;
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  @media #{$medium-up} {
    padding: vr(1) vr(2);
    &:before,
    &:after {
      width: rem-calc(380);
      left: 50%;
      margin-left: rem-calc(-190);
    }
  }
}

// baixar arxiu destacat
.dl {
  @include typi(s1);
  background-color: #e9e9e9;
  padding: vr(.25) vr(.5);
  position: relative;
  &:before {
    content: '';
    // @extend .baixar-destacat;
    display: block;
    position: absolute;
    left: -21px;
    top: 50%;
    margin-top: -8px;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  border-left: 28px solid $primary-color;
}

// resultat cerca
.result {
  text-transform: none;
  background-color: lighten($primary-color,60%);
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(.75);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.cal-tools {
  @media #{$medium-up} {
    position: relative;
    .button {
      position: absolute;
      right: $column-gutter/2;
      top: vr(.5);
    }
  }
}

.text-wrapper {
  margin-bottom: vr(2);
  @media #{$medium-up} {
    padding: 0 vr(1.5);
  }
}
.text-wrapper--border {
  @media #{$medium-up} {
    border-left: 1px solid #979797;
  }
  p {
    &:before {
      content: '';
      display: block;
      margin-bottom: vr(.5);
      @extend .icons-ids;
    }
  }
}

// criteri
.criteri {
  margin-top: vr(1.5);
  @media #{$small-only} {
    text-align: center;
  }
  &:before {
    content: '';
    display: block;
    margin: 0 auto vr(.5);
    @media #{$medium-up} {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &--plantilla {
    &:before {
      @extend .ico-persones-2;
      @media #{$medium-up} {
        margin-top: 3px !important;
      }
    }
  }
  &--espais {
    &:before {
      @extend .ico-espais;
    }
  }
}

// color destacat
.highlight {
  @include bold;
  color: #FFF896;
}

// p amb icon empreses
.empreses {
  &:before {
    display: block;
    margin: 0 auto vr(.5);
    content: '';
    @extend .ico-empreses;
  }
}
span.anulada{
  border: solid 1px #EA004B;
  padding: vr(.5) vr(.5) vr(.5)  vr(1.7);
  line-height: 1.2;
  //display: inline-block;
  display: block;
  font-size: .9em;
  background: #EA004B;
  color: #fff;
  margin: vr(.5) 0;
  position: relative;
  border-radius: 6px;
  .properes-donacions & {
    margin-top: 0;
    //display: block;
  }
  .si-wrapper-top & {
    display: inline-block;
    margin-top: 0;
  }
  .fitxa & {
    display: inline-block;
    margin-top: 0;
  }
  &:before {
    display: block;
    margin: 0 auto vr(.5);
    content: '';
    position: absolute;
    left: 8px;
    top: 8px;
    height: 30px;
    width: 30px;
    background-color: #fff;
    -webkit-mask:  url('/media/img/ico-alerta-reserves.svg') no-repeat 0 0;
    mask: url('/media/img/ico-alerta-reserves.svg') no-repeat 0 0;
    mask-size: 30px 30px;
  }
}
a.anulada-more{
  opacity: 0;
}
a.anulada{
  span{
    display: block;
    color:#fff;
    font-size: .9em;
    position: relative;
    padding-left: 20px;
    padding: vr(.3) 20px vr(.3);
    border-radius: 6px;
    background: darken($primary-color,10%);
    &:before {
      display: block;
      margin: 0 auto vr(.5);
      content: '';
      position: absolute;
      left: 2px;
      top: 8px;
      height: 14px;
      width: 14px;
      background-color: #fff;
      -webkit-mask:  url('/media/img/ico-alerta-reserves.svg') no-repeat 0 0;
      mask: url('/media/img/ico-alerta-reserves.svg') no-repeat 0 0;
      mask-size: 14px 14px;
    }
  }
}
#dades-reserva {
  img {
    margin-bottom: rem-calc(20);
  }
}

.txt-sota{
    margin-top: vr(2);
    padding-top: 25px;
    border-top:1px solid $primary-color;
}

table:not(.calendari):not(.cal) {
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%!important;
  display: block;
  border-spacing: 3px;
  border-collapse: collapse;
  tr{
    th{
      background-color: $primary-color;
      line-height: 1.2;
      font-weight: 400;
      color: #fff;
      min-width: 100px;
      font-size: .8125rem;
      padding: 3px 5px;
    }
    td {
        line-height: 1.2;
        position: relative;
        padding: 1.5em 0.9375em;
        text-align: left;
        font-size: .75rem;
    }
  }
}