.afegir-agenda{
	position: relative;
}
.boto-calendari{
    display: inline-block;
    background-color: #fff;
    border: 1px solid transparent;
    padding: rem-calc(6) rem-calc(10) rem-calc(6) rem-calc(36);
    box-shadow: 0 0 0 0.5px rgba(50,50,93,.17), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent !important;
    border-radius: 3px;
    margin:1em 0 2.5em;
    font-size: rem-calc(14);
    position: relative;
    color: #414141;
    &:hover{
    	background-color: #fafafa;
    }
    &:active{
    border-width: 2px 1px 0px 1px;
    }
    svg{
    	position: absolute;
    	left:rem-calc(10);
    	top: 50%;
    	margin-top: -12px;
    	fill:#414141;
    }
}


.calendari-opcions{
	width: 230px;
    position: absolute;
    padding: 6px 0px 0px 0px;
    margin-left: -1px;
    display: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    -moz-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    transform: scale(.98,.98) translate(-50%,-20%);
    opacity: 0.5;
    z-index: -1;
    transition: transform .15s ease;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background: #fff;
    left: 50%;
    top: 0;
    transform: translate(-50%,-50%);
    &.selected{
    	opacity: 1;
    	transform: scale(1,1) translate(-50%,-35%);
    	z-index: 10;
    	display: block;
    }
    #tancar-calendari{
    	position: absolute;
	    right: -46px;
	    top: 0;
	    padding: 10px;
	    background-color: #efefef;
	    margin: 0;
	    line-height: 1;
    	img{
    		margin:0;
    	}
    }
    ul{
	    @include undolist;
	    li{
	    	margin:.25em 0;
	    	text-align: left;
	    	a{
	    		-webkit-transition: background-color .3s;
	    		transition: background-color .3s;
	    		display: block;
	    		color:#414141;
	    		text-decoration: none;
	    		border: none;
	    		padding: .6em 1em .6em 55px;
	    		background: url(img/ical.svg) 20px 48% no-repeat;
	    		background-size: 24px 24px;
	    		&.apple{
	    			background: url(img/ico-apple.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.google{
	    			background: url(img/ico-google.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.outlook{
	    			background: url(img/ico-outlook.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.outlookcom{
	    			background: url(img/ico-outlookcom.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&:hover{
	    			background-color: #efefef;
	    		}
	    	}
	    }
	}
}

