.icons-list {
  margin-bottom: rem-calc(25);
  li {
    display: inline-block;
    vertical-align: middle;
  }
  a {
    display: inline-block;
    margin: 0 rem-calc(12);
    transition: opacity .1s;
    vertical-align: middle;
    &:hover {
      opacity: .8;
      border: 0;
    }
  }
  
}
