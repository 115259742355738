.block-list {
  @include block-grid(1);
  margin-bottom: vr(1.5) !important;
  @media #{$medium-up} {
    @include block-grid(2);
  }
  @media #{$large-up} {
    @include block-grid(3);
  }
  li {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
  &--centered {
    font-size: 0;
    text-align: center;
    div {
      text-align: left;
    }
    >li {
      display: inline-block;
      float: none;
      vertical-align: middle;
    }
  }
}