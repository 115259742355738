.boxes-basic{
  margin: 2em 1em;

  li {        
    margin-bottom: 2em;
    padding: 2em;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    transition: all .2s ease-in-out;     
    box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);   

    h2{
      margin: .5em 0;
    }

    form{
      margin-top: 2em;
      fieldset{
        padding: 0;
      }
    }

    svg {
      display: block;
      margin:0 auto .5em;
    }
  }

  @media #{$medium} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    li {
      flex-basis: calc(50% - 2em);        
      border-radius: 5px;
      transition: all .2s ease-in-out;
      align-items: center;
    }
  }
  @media #{$large} {
    li {
      flex-basis: calc(50% - 6em);        
      
    }
  }
}

.circle-dot{
  display: inline-block;
  background: #db1e7e;
  padding: 14px;
  border-radius: 100%;
  width: 60px;
  height: 60px;

  svg{
    color:#fff;
  }

  &.a{
    background-color:#83A9FF;
  }

  &.b{
    background-color: #C2006A;
  }
}