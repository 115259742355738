// webfonts declarations
@font-face {
    font-family: 'open_sansextrabold';
    src: url('/media/fonts/Open-Sans/Open-Sans-Extrabold.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans-Extrabold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans-Extrabold.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans-Extrabold.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans-Extrabold.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans-Extrabold.svg#open_sansextrabold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('/media/fonts/Open-Sans/Open-Sans-Bold.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans-Bold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans-Bold.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans-Bold.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans-Bold.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans-Bold.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'open_sans_semiboldregular';
    src: url('/media/fonts/Open-Sans/Open-Sans-Semibold.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans-Semibold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans-Semibold.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans-Semibold.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans-Semibold.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans-Semibold.svg#open_sans_semiboldregular') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'open_sansitalic';
    src: url('/media/fonts/Open-Sans/Open-Sans-Italic.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans-Italic.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans-Italic.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans-Italic.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans-Italic.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans-Italic.svg#open_sansitalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('/media/fonts/Open-Sans/Open-Sans.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'open_sans_lightregular';
    src: url('/media/fonts/Open-Sans/Open-Sans-Light.eot');
    src: url('/media/fonts/Open-Sans/Open-Sans-Light.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Open-Sans/Open-Sans-Light.woff2') format('woff2'),
         url('/media/fonts/Open-Sans/Open-Sans-Light.woff') format('woff'),
         url('/media/fonts/Open-Sans/Open-Sans-Light.ttf') format('truetype'),
         url('/media/fonts/Open-Sans/Open-Sans-Light.svg#open_sans_lightregular') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}